:root {
	--main_color: #16b364;
	--main_color_dark: #099250;
	--text_color: #344054;
	--text_color_low: #667085;
	--text_color_lower: #34405499;
	--border_color: #d0d5dd;
	--border_color_low: #d0d5ddcc;
	--white_bg: #fff;
	--gray_bg: #f7f8f9;
	--white_text: #fff;
	--btn_main_shadow: 0px 0px 0px 4px rgba(9, 146, 80, 0.14);
	--btn_gray_shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(152, 162, 179, 0.14);
}
