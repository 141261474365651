.container {
	display: flex;
	align-items: center;
	flex-direction: column;
	margin: 72px 15px 50px;
}
.timeout {
	width: 100%;
	max-width: 546px;
	height: 18px;
	border-radius: 99px;
	padding: 3px;
	border: 1px solid var(--border_color_low);
	background: var(--white_bg);
	box-shadow: 0px 12px 16px -4px #767f9214, 0px 4px 6px -2px #767f9208;
	margin-bottom: 28px;
}
.timeout .fill {
	border-radius: 999px;
	background: var(--main_color);
	height: 10px;
	transition: 1s;
	width: 0;
	position: relative;
}
.timeout .tooltip {
	position: absolute;
	top: -50px;
	left: -20px;
	line-break: strict;
	color: var(--text_color);
	background-color: var(--white_bg);
	z-index: 1;
	min-width: 100px;
	border-radius: 10px;
	padding: 10px;
	color: var(--text_color_lower);
	font-size: 12px;
	font-weight: 600;
	border: 1px solid var(--border_color);
}
.timeout .tooltip span {
	color: var(--text_color_low);
}
.tooltip p {
	direction: ltr;
	display: flex;
	gap: 12px;
}

.arrowDown {
	width: 8px;
	height: 8px;
	background-color: var(--white_bg);
	position: absolute;
	left: 20px;
	bottom: -4px;
	transform: rotate(45deg);
	border-right: 1px solid var(--border_color);
	border-bottom: 1px solid var(--border_color);
}
.timeout .lastTime {
	left: -100px;
}
.timeout .lastTime .arrowDown {
	left: 85%;
	bottom: -4px;
}
.paymentCard {
	width: 100%;
	max-width: 546px;
	margin: 0 10px 26px;
	background-color: var(--white_bg);
	border-radius: 16px;
	border: 1px solid var(--border_color_low);
	box-shadow: 0px 12px 16px -4px rgba(118, 127, 146, 0.12), 0px 4px 6px -2px rgba(118, 127, 146, 0.03);
}
.paymentCard .head {
	padding: 24px;
	display: flex;
	gap: 16px;
	align-items: center;
	height: 100px;
	border-bottom: 1px solid var(--border_color_low);
	font-weight: 400;
}
.paymentCard .head p {
	color: var(--text_color_lower);
	font-weight: 400;
}
.paymentCard .backBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 48px;
	height: 48px;
	border-radius: 10px;
	border: 1px solid rgba(208, 213, 221, 0.5);
	background: #f9fafb;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	transition: background-color 0.3s, box-shadow 0.3s;
}
.backBtn:hover {
	background-color: var(--gray_bg);
}
.backBtn:active {
	box-shadow: var(--btn_gray_shadow);
}
.paymentCard .body {
	padding: 24px;
}
@keyframes fillAnimation {
	0% {
		width: 100%;
	}
	100% {
		width: 0%;
	}
}
