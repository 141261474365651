.selects p {
	color: #344054cc;
	font-size: 12px;
	font-weight: 500;
}
.selects > div {
	box-shadow: 0px 1px 2px 0px #1018280d;
	background: var(--gray_bg);
	display: flex;
	align-items: center;
	border: 1px solid var(--border_color);
	height: 40px;
	border-radius: 10px;
	padding: 0 12px;
	margin-top: 10px;
}
.selects p {
	font-weight: 600;
}
.selects .coin {
	flex: 0.6;
	display: flex;
	align-items: center;
	border-right: 1px solid var(--border_color);
	height: 100%;
	gap: 12px;
}
.selects .coin img {
	width: 20px;
	height: 20px;
}
.selects .network {
	flex: 0.4;
	height: 100%;
	display: flex;
	align-items: center;
	padding-left: 12px;
}
.selects .network span {
	font-size: 12px;
	font-weight: 500;
	color: var(--text_color_lower);
	margin-right: 8px;
}
.payInfo {
	margin-top: 24px;
	border-radius: 12px;
	padding: 10px;
	background-color: var(--gray_bg);
	display: flex;
	gap: 10px;
	width: 100%;
}
.payInfo .info {
	flex: 1;
}
.payInfo .qrcode {
	height: 128px;
	background-color: var(--white_bg);
	box-shadow: 0px 2px 4px #1018280d;
	border-radius: 10px;
}
.payInfo .qrcode img {
	padding: 8px;
	width: 124px;
}

.payInfo .amount,
.payInfo .address,
.payInfo .convert {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
	margin: 0 10px 10px;
	border-bottom: 1px solid var(--border_color);
}
.payInfo .address {
	margin-top: 19px !important;
}
.payInfo .amount p {
	color: var(--text_color);
	font-weight: 600;
	font-size: 14px;
}
.payInfo .amount span {
	color: var(--text_color);
	font-weight: 700;
	font-size: 14px;
}
.payInfo .convert {
	color: var(--text_color_lower);
	font-weight: 500;
	margin: 0 10px 0;
	padding: 3px 0;
	border-bottom: none;
}
.payInfo .convert p,
.payInfo .convert span {
	font-size: 12px !important;
}
.payInfo .address {
	display: flex;
	align-items: center;
	border-bottom: none;
	margin: 0;
	margin-top: 5px;
	height: 40px;
	border: 1px solid var(--border_color);
}
.payInfo .address {
	box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
	border-radius: 10px;
}

.address input {
	background-color: var(--gray_bg);
	padding: 0 9px 0 12px;
	width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 12px;
	font-weight: 500;
	color: var(--text_color_low);
}

.address button {
	margin-left: 10px;
	transition: background-color 0.3s, box-shadow 0.3s;
}
.address button:hover {
	background-color: var(--gray_bg) !important;
}
.address button:active {
	box-shadow: var(--btn_gray_shadow);
}
.payInfo .address button {
	display: flex;
	gap: 12px;
	padding: 0 12px;
	border-radius: 0 10px 10px 0;
	background-color: var(--white_bg);
	max-height: 38px;
	align-items: center;
	font-size: 14px;
	color: var(--text_color);
	font-weight: 600;
	border-left: 1px solid var(--border_color);
}

.payBtn {
	background-color: transparent;
	border-radius: 10px;
	border: 1px solid var(--border_color);
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	height: 40px;
	transition: background-color 0.3s;
	margin-top: 24px;
	transition: background-color 0.3s, box-shadow 0.3s;
}
.payBtn:hover {
	background-color: var(--gray_bg);
}
.payBtn:active {
	box-shadow: var(--btn_gray_shadow);
}
.modalContainer h2 {
	margin: 10px 0 5px;
	color: var(--text_color);
}
.modalContainer > p {
	color: var(--text_color_lower);
	margin-bottom: 24px;
}
.modalContainer .info {
	background-color: var(--gray_bg);
	padding: 24px;
	border-radius: 12px;
	margin-bottom: 24px;
}
.modalContainer .info > div {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 8px 0;
	color: var(--text_color_low);
}
.modalContainer .info > div span {
	font-size: 12px;
}
.modalContainer .info .tHash {
	color: var(--text_color);
}
.modalContainer .info hr {
	margin: 15px 0;
	opacity: 0.4;
}

.modalContainer .info .foot {
	font-size: 14px;
	font-weight: 600;
}
.modalContainer button {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 44px;
	gap: 8px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid var(--border_color);
	background: var(--white_bg);
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	margin-top: 10px;
	transition: background-color 0.3s, box-shadow 0.3s;
}

.modalContainer .viewBtn:hover {
	background-color: var(--gray_bg);
}
.modalContainer .viewBtn:active {
	box-shadow: var(--btn_gray_shadow);
}
.modalContainer .receiptBtn {
	background-color: var(--main_color);
	color: var(--white_text);
	border: 1px solid var(--main_color);
}

.modalContainer .receiptBtn:hover {
	background-color: var(--main_color_dark);
	border: 1px solid var(--main_color_dark);
}
.modalContainer .receiptBtn:active {
	box-shadow: var(--btn_main_shadow);
}
@media screen and (width< 530px) {
	.payInfo {
		align-items: center;
		flex-direction: column;
	}
}
