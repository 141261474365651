.container {
	display: flex;
	flex-direction: column;
	gap: 24px;
}
.option {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	border: 1px solid var(--border_color_low);
	background: #fff;
	box-shadow: 0px 2px 4px 0px #1018280d;
	padding: 0 14px;
	cursor: pointer;
	transition: background-color 0.3s, box-shadow 0.3s;
}
.option:hover {
	background-color: var(--gray_bg);
}
.option:active {
	box-shadow: var(--btn_gray_shadow);
}
.option .info {
	display: flex;
	align-items: center;
	gap: 16px;
}
.option button {
	display: flex;
	align-items: center;
	gap: 14px;
	height: 28px;
	border-radius: 6px;
	border: 1px solid var(--border_color_low);
	padding: 0 6px;
	background: var(--white_bg);
	box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
	transition: background 0.3s;
}
.option button > div {
	display: flex;
	align-items: center;
	gap: 6px;
}

.option img[alt="bitcoin icon"],
.option img[alt="ethereum icon"],
.option img[alt="polygon icon"] {
	width: 16px;
	height: 16px;
}

.option img[alt="masketcard icon"] {
	width: 19px;
}
.option img[alt="visacard icon"] {
	width: 26px;
}
.balance {
	background-color: var(--gray_bg);
	border-radius: 16px;
}
.balance .foot {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 16px;
}
.balance .foot p {
	color: var(--text_color_low);
}
.balance .foot p > span {
	color: var(--text_color);
	font-weight: 600;
}
.balance .foot > span {
	padding: 2px 8px;
	color: var(--main_color);
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 18px;
	border-radius: 6px;
	border: 1px solid var(--border_color);
	background: #fff;
	box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
}
