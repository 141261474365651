.modalBackdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 98;
	backdrop-filter: blur(5px);
}
.modal {
	background: white;
	padding: 24px;
	border-radius: 16px;
	position: relative;
	z-index: 99;
	animation: fadeIn 0.3s ease-in-out;
	max-width: 80%;
	max-height: 80%;
	overflow: auto;
}

.closeButton {
	position: absolute;
	top: 15px;
	right: 25px;
	background: none;
	border: none;
	font-size: 30px;
	cursor: pointer;
	padding: 0;
	color: black;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(20px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}
