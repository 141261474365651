.container {
	display: flex;
	flex-direction: column;
	gap: 24px;
}
.searchbar {
	display: flex;
	padding: 8px 12px;
	align-items: center;
	gap: 8px;
	border-radius: 10px;
	border: 1px solid #d0d5dd;
	background: #fff;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
	transition: border 0.3s, box-shadow 0.3s;
}

.searchbar input {
	width: 100%;
}
.searchbar:focus-within {
	border: 1px solid #aaf0c4;
	box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px rgba(22, 179, 100, 0.14);
}
.searchbar input::placeholder {
	color: var(--text_color_low);
	font-weight: 500;
}
.networkList {
	display: flex;
	padding: 14px;
	flex-direction: column;
	align-items: center;
	gap: 4px;
	background-color: var(--gray_bg);
	border-radius: 10px;
	overflow-y: scroll;
	min-height: 320px;
	max-height: 320px;
}
.item {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 44px;
	padding: 0 14px 0 10px;
	cursor: pointer;
	border-radius: 10px;
	border: 1px solid transparent;
	background: transparent;
	box-shadow: none;
	transition: border 0.3s, background 0.3s, box-shadow 0.3s;
}
.item:hover {
	border: 1px solid rgba(208, 213, 221, 0.9);
	background: var(--white_bg);
	box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
}
.item > div {
	display: flex;
	align-items: center;
	gap: 8px;
}
.item .icon {
	width: 24px;
	height: 24px;
}

.container button {
	color: var(--white_text);
	font-size: 16px;
	font-weight: 600;
	height: 44px;
	border-radius: 10px;
	background-color: var(--main_color);
	transition: background-color 0.3s;
	transition: background-color 0.3s, box-shadow 0.3s;
}

.container button:hover {
	background-color: var(--main_color_dark);
}
.container button:active {
	box-shadow: var(--btn_main_shadow);
}
.container button:disabled {
	background-color: var(--gray_bg);
	color: var(--text_color_lower);
}
.networkList .selectedItem {
	border: 1px solid rgba(208, 213, 221, 0.9);
	background: var(--white_bg);
	box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
}
.alertMessage {
	color: tomato;
	text-align: center;
	background-color: rgba(255, 99, 71, 0.151);
	padding: 10px;
	border-radius: 10px;
}
