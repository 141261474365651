.header {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 80px;
	background-color: var(--white_bg);
	box-shadow: 0px 10px 16px -4px rgba(118, 127, 146, 0.06), 0px 4px 6px -2px rgba(118, 127, 146, 0.03);
}
.container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	max-width: 1116px;
	margin: 0 10px;
}
.left {
	display: flex;
	align-items: center;
	gap: 40px;
}
.logo {
	display: flex;
	align-items: center;
}
.burgerMenue {
	width: 18px;
	display: none;
}
.burgerMenue img {
	width: 100%;
}
.logo h1 {
	width: 1420px;
	height: 32px;
}
.navbar ul {
	display: flex;
}
.navbar ul li {
	margin-right: 32px;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px;
}
.navbar ul li img {
	margin-left: 8px;
}
.navbar ul span {
	cursor: pointer;
	font-size: 16px;
	color: var(--text_color_low);
}
.btns {
	display: flex;
	gap: 12px;
}
.btns button {
	border-radius: 10px;
	height: 44px;
	padding: 0 18px;
	border: 1px solid var(--border_color);
	font-size: 14px;
	font-weight: 600;
	color: var(--text_color);
}
.btns .login {
	background-color: var(--white_color);
	transition: background-color 0.3s, box-shadow 0.3s;
}
.btns .login:hover {
	background-color: var(--gray_bg);
}
.btns .login:active {
	box-shadow: var(--btn_gray_shadow);
}
.btns .signup {
	background-color: var(--main_color);
	color: var(--white_text);
	border: none;
	transition: background-color 0.3s, box-shadow 0.3s;
}
.btns .signup:hover {
	background-color: var(--main_color_dark);
}
.btns .signup:active {
	box-shadow: var(--btn_main_shadow);
}

@media screen and (width < 900px) {
	.burgerMenue {
		display: block;
	}
	.navbar {
		display: none;
	}
	.logo img {
		max-width: 120px;
	}
	.left {
		gap: 20px;
	}
	.btns button {
		height: 40px;
		padding: 0 15px;
		font-size: 12px;
	}
}
