@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	font-size: 16px;
	font-family: "Inter", sans-serif;
	background-image: url("/public/images/background.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top center;
}

a {
	text-decoration: none;
	color: inherit;
}

button {
	border: none;
	outline: none;
	cursor: pointer;
	height: 44px;
}

input,
textarea,
select,
button {
	border: none;
	outline: none;
	font-family: inherit;
	font-size: inherit;
}

li {
	list-style: none;
}

h1 {
	font-size: 18px;
	font-weight: 600;
	line-height: 28px;
}
h2 {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
}
p {
	font-size: 14px;
}

div::-webkit-scrollbar {
	width: 8px;
}

div::-webkit-scrollbar-thumb {
	background-color: #0000002a;
	border-radius: 6px;
}

div::-webkit-scrollbar-track {
	margin: 5px;
	background-color: transparent;
}

div::-webkit-scrollbar-thumb:hover {
	background-color: #555;
}

@media screen and (width < 900px) {
	h1 {
		font-size: 16px;
	}
	h2 {
		font-size: 14px;
	}
	p {
		font-size: 12px;
	}
}
